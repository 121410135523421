// src/context/AuthContext.tsx

import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getAuth,
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { UserCredential } from "firebase/auth/cordova";
import firebase_app from "src/firebase/config";

// Define the type right here for the function
type SyncUserWithUserGuidingType = (user: User) => void;

// This will hold the dynamically imported function
let syncUserWithUserGuidingLocal: SyncUserWithUserGuidingType | undefined;

(async () => {
  const userGuiding = await import("src/context/userGuiding");
  syncUserWithUserGuidingLocal = userGuiding.syncUserWithUserGuiding;
})();

interface AuthContextType {
  appInitialized: boolean;
  user: User | null;
  signIn: (email: string, password: string) => Promise<UserCredential>;
  signUp: (email: string, password: string) => Promise<UserCredential>;
  signOut: () => Promise<void>;
}

export function tryPostMessage(userCredential: UserCredential) {
  try {
    window.postMessage({ user: userCredential.user }, "*");
  } catch (error) {
    console.error(error);
  }
}
export function tryPostString(user: string){
  try{
    window.postMessage({ user: user }, "*");
  }catch(error){
    console.error(error);
  }
}
export function tryPostUser(user: User){
  try{
    window.postMessage({ user: user }, "*");
  }catch(error){
    console.error(error);
  }
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [appInitialized, setAppInitialized] = useState(false);
  const [user, setOutUser] = useState<User | null>(() => {
    const currUser = getAuth(firebase_app).currentUser;
    return currUser;
  });

  useEffect(() => {
    console.log("Effect mounted");
    const timer = setTimeout(() => {
      setAppInitialized(true);
      console.warn("onAuthStateChanged did not fire within 5 seconds!");
    }, 5000);
    const unsubscribe = getAuth(firebase_app).onAuthStateChanged(
      (currentUser) => {
        clearTimeout(timer);
        setAppInitialized(true);
        setOutUser(currentUser);
        if (currentUser && syncUserWithUserGuidingLocal) {
          try {
            syncUserWithUserGuidingLocal(currentUser);
          } catch (err) {
            console.error("Error syncing user with UserGuiding", err);
          }
        }
      },
    );

    return () => {
      console.log({ unsub: "now" });
      console.log("Effect unmounting - unsubscribing");
      return unsubscribe();
    };
  }, []);

  const signIn = async (email: string, password: string) => {
    const userCredential = await signInWithEmailAndPassword(
      getAuth(firebase_app),
      email,
      password,
    );
    if (syncUserWithUserGuidingLocal) {
      try {
        syncUserWithUserGuidingLocal(userCredential.user);
      } catch (error) {
        console.error("Error syncing user with UserGuiding", error);
      }
    }
    tryPostMessage(userCredential);
    return userCredential;
  };

  const signUp = async (email: string, password: string) => {
    const userCredential = await createUserWithEmailAndPassword(
      getAuth(firebase_app),
      email,
      password,
    );
    if (syncUserWithUserGuidingLocal)
      syncUserWithUserGuidingLocal(userCredential.user);
    try {
      tryPostUser(userCredential.user);
    } catch (e) {
      console.error(e);
      console.log("error in posting message");
    }
    return userCredential;
  };

  const signOut = async () => {
    await getAuth(firebase_app).signOut();
    // Assume `window.userGuiding` exists and has a `reset` method as previously set up in global types
    if (window.userGuiding && window.userGuiding.reset) {
      window.userGuiding.reset();
    }
  };

  return (
    <AuthContext.Provider
      value={{ appInitialized, user, signIn, signOut, signUp }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
