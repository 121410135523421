import React, { useState } from "react";
import CustomDropdown from "../elements/CustomDropDownMenu";
import { IconButton } from "../elements/IconButton";
import { IconItem } from "../../pages/sidebar/ChatNameBox";
import ChatIcon from "../svgs/Chat";
import RowsIcon from "../svgs/Rows";
import ThreeDotsIcon from "../svgs/ThreeDots";
import DownloadIcon from "../svgs/Download";
import DeleteIcon from "../svgs/Delete";
import EditIcon from "../svgs/Edit";
import ShareIcon from "../svgs/Share";
import useChatDetails from "src/hooks/useChatDetails";
import { useStateContext } from "src/context/StateContext";
import { getColumnDisplayName, numberFormatter } from "../utils";
import { Column, RowFilter } from "src/hooks/useChatTable";
import StarsIcon from "../svgs/Stars";
import KurationSmallIcon from "../svgs/KurationSmall";
import {
  FunnelIcon,
  PencilIcon,
  PlayIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import backend_services from "src/services/backend_service";
import { useParams } from "react-router-dom";
import { useAuth } from "src/context/AuthContext";
import { ChangeEvent } from "react";
import { SimpleSpinner } from "../elements/Spinner";
// import { FilterList } from "src/components/table/filterList";
import Switcher3 from "../elements/Switcher3";
import { toast } from "react-toastify";
import {
  FilterFields,
  FilterFormField,
  getUpdatedForm,
} from "../preCuratedLists/FilterFields";
import { filterOptions } from "../preCuratedLists/PreCuratedListFilters";
import FilterIcon from "../svgs/Filter";
import { deleteRows } from "../utils";
import ColumnIcon from "../svgs/ColumnIcon";

export default function TableOptions({
  rowCount,
  uniqueCols,
  column_list,
  row_filters,
  columnFilters,
  downloadHandler,
  type,
  filter_running_state_counter,
  rowIds,
}: {
  rowCount: number;
  uniqueCols: number;
  type: "search" | "default" | "final" | "noTable";
  column_list?: Array<Column>;
  row_filters: { [key: string]: RowFilter };
  columnFilters?: any[];
  downloadHandler: () => void;
  filter_running_state_counter: number;
  rowIds?: string[];
}) {
  const { chatId: currentOpenChat } = useParams();
  const { chatDetails } = useChatDetails(currentOpenChat ?? null);
  const tableId = chatDetails?.table_id;
  const { user } = useAuth();
  const { chatId } = useParams();
  const { setChatOpen, selectedRows, setSelectedRows, setFiltersOpen } =
    useStateContext();
  const [runAllColumnsLoading, setRunAllColumnsLoading] = useState(false);

  const handleRunAllColumns = () => {
    console.log("run all columns");
    setRunAllColumnsLoading(true);
    backend_services
      .fetchProtectedData(
        `/chat/${chatId}/run_all_non_running_columns`,
        user?.getIdToken(),
        undefined,
        "POST",
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        toast.error("Failed to run columns. Please try again.");
      })
      .finally(() => setRunAllColumnsLoading(false));
  };
  const activeFilters = Object.values(row_filters || {}).filter(
    (f) => f.is_active,
  );

  const matchRowIds = (rowIds: string[], selectedRows: number[]): string[] => {
    return selectedRows
      .filter((index) => index < rowIds.length) // Ensure index is valid for rowIds
      .map((index) => rowIds[index]); // Return rowIds at those indices
  };

  const matchedRowIds = matchRowIds(rowIds ?? [], selectedRows);
  const totalRows = rowIds?.length || 0;
  const selectedRowCount = matchedRowIds.length;

  function handleDeleteRows() {
    if (!user) return;

    const toastId = toast.loading("Deleting rows...");

    try {
      deleteRows(chatId ?? "", tableId ?? "", matchedRowIds, user?.getIdToken())
        .then(() => {
          // Update the toast to success once the rows are deleted
          toast.update(toastId, {
            render: "Rows deleted successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });

          // Unselect rows by clearing selectedRows
          setSelectedRows([]);
        })
        .catch((error) => {
          console.error("Error deleting rows:", error);

          // Update the toast to error if something goes wrong
          toast.update(toastId, {
            render: "Failed to delete rows.",
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
        });
    } catch (e) {
      console.error("Unexpected error:", e);

      // Update the toast to error for unexpected errors
      toast.update(toastId, {
        render: "An unexpected error occurred.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  }

  return (
    <div className=" flex flex-grow flex-row items-center justify-between gap-6 md:gap-2">
      <div className="flex flex-row items-center gap-1 md:gap-2">
        <CustomDropdown
          overrideClasses="min-w-min"
          opener={
            <IconItem
              collapsible={true}
              icon={
                <>
                  {(filter_running_state_counter > 0 ||
                    chatDetails?.duplicate_chat_loading) && (
                    <SimpleSpinner radius={20} overrideClasses="border-black" />
                  )}
                  <RowsIcon />
                </>
              }
              onClick={() => {
                // updateTotalCount?.();
                // fetchFilters();
              }}
              text={
                <>
                  <span>{numberFormatter.format(rowCount)} Rows</span>
                </>
              }
              overrideClasses="rounded-md bg-transparent hover:bg-lightpurple"
            />
          }
          options={[]}
        />

        <CustomDropdown
          opener={
            <IconItem
              collapsible={true}
              icon={<ColumnIcon />}
              onClick={() => {}}
              text={`${uniqueCols || 0} Columns`}
              overrideClasses="rounded-md bg-transparent hover:bg-lightpurple"
            />
          }
          overrideClasses="max-h-[400px] w-96 overflow-y-auto"
          options={[
            <IconButton
              overrideClasses="justify-start border-none px-2.5"
              icon={
                runAllColumnsLoading ? (
                  <SimpleSpinner radius={20} overrideClasses="border-black" />
                ) : (
                  <PlayIcon width={20} />
                )
              }
              text={"Run all columns"}
              onClick={handleRunAllColumns}
              variant={"outline"}
            />,
            <ColumnOptions columns={column_list || []} />,
          ]}
        />

        <CustomDropdown
          overrideClasses="min-w-min"
          opener={
            <IconItem
              collapsible={true}
              icon={
                <>
                  <FilterIcon />
                </>
              }
              onClick={() => {
                // updateTotalCount?.();
                // fetchFilters();
              }}
              text={
                <>
                  <span>{activeFilters.length} applied filters</span>
                </>
              }
              overrideClasses="rounded-md bg-transparent hover:bg-lightpurple"
            />
          }
          options={[
            <DefaultTableFilterForm
              column_list={column_list || []}
              row_filters={row_filters || {}}
            />,
            // <FilterList
            //   filters={filters}
            //   isLoading={isLoading}
            //   onFilterUpdate={handleFilterUpdate}
            //   user={user}
            //   chatId={chatId}
            // />,
          ]}
        />
      </div>
      <div>
        <div className="hidden flex-row gap-2 md:flex">
          {matchedRowIds.length > 0 ? (
            <>
              <div className="flex items-center gap-2">
                <p className="text-sm text-gray-500">
                  {selectedRowCount}/{totalRows} rows selected
                </p>
                <IconButton
                  overrideClasses={`h-[42px] w-[42px] flex-grow gap-0 border-purple p-1.5 ${
                    matchedRowIds.length > 0 ? "" : "cursor-not-allowed"
                  }`}
                  onClick={handleDeleteRows}
                  variant="outline"
                  icon={<DeleteIcon className="h-6 w-6" />}
                  text={<></>}
                />
              </div>
            </>
          ) : (
            <>
              <IconButton
                title={`Max ${type === "default" ? "10,000" : "1000"} rows`}
                overrideClasses="h-[42px] w-[42px] flex-grow gap-0 border-purple p-1.5"
                onClick={() => {
                  downloadHandler();
                }}
                variant="outline"
                icon={<DownloadIcon className="h-6 w-6" />}
                text={<></>}
              />
              <IconButton
                overrideClasses="h-[42px] flex-grow gap-1 border-purple  p-1.5 text-black"
                onClick={() => {
                  setChatOpen(true);
                  setFiltersOpen(false);
                }}
                variant="outline"
                icon={<ChatIcon className="h-6 w-6" />}
                text="AI Chat"
              />
            </>
          )}
        </div>
        <div className=" hidden h-[42px] w-[42px] items-center justify-center">
          <CustomDropdown
            opener={
              <IconItem
                collapsible={true}
                icon={<ThreeDotsIcon />}
                onClick={() => {}}
                text="Settings"
                overrideClasses="rounded-md bg-transparent hover:bg-lightpurple  "
              />
            }
            options={chatOptions()}
            overrideClasses="left-8"
          />
        </div>
      </div>
    </div>
  );
}

const DefaultTableFilterForm = ({
  column_list,
  row_filters,
}: {
  column_list: Column[];
  row_filters: { [id: string]: RowFilter };
}) => {
  const { chatId } = useParams();
  const { user } = useAuth();
  const [rowFilters, setRowFilters] = useState<FilterFormField[]>([]);
  const [runFilterLoading, setRunFilterLoading] = useState(false);

  const handleFormUpdate = (
    f: FilterFormField,
    k: keyof FilterFormField,
    v: string | number | boolean,
  ) => {
    setRowFilters((prev) =>
      getUpdatedForm({
        prev,
        updatedField: f,
        k,
        // if the updated field is value and the type is exists, then convert the value to a boolean
        v:
          k === "value" && f.type === "exists" ? v === true || v === "true" : v,
      }),
    );
  };

  const submit = (filterForm: FilterFormField[]) => {
    const filterToSend: { [key: string]: any }[] = [];
    filterForm.forEach((f) => {
      if (f.type === "greaterThan" || f.type === "lessThan") {
        f.value = parseInt(f.value as string);
      }
      if (f.type === "exists") {
        f.value = f.value === true || f.value === "true";
      }
      filterToSend.push(f);
    });
    console.log(filterToSend);
    if (filterToSend.length > 0) {
      setRunFilterLoading(true);
      backend_services
        .fetchProtectedData(
          `/chat/${chatId}/table/add_filter`,
          user?.getIdToken(),
          undefined,
          "POST",
          { filters: filterToSend },
        )
        .then((res) => {
          if (res.status === 200) {
            toast.success("Filters applied successfully.");
            setRowFilters([]);
          } else {
            toast.error("Failed to run filters. Please try again.");
          }
        })
        .catch((err) => {
          toast.error("Failed to run filters. Please try again.");
        })
        .finally(() => {
          setRunFilterLoading(false);
        });
    }
  };

  const handleFilterRemove = (f: FilterFormField) => {
    setRowFilters((prev) => {
      const updatedFilters = prev.filter((ff) => ff.id !== f.id);
      submit(updatedFilters);
      return updatedFilters;
    });
  };
  if (runFilterLoading) {
    return <SimpleSpinner radius={20} overrideClasses="border-black p-4" />;
  }
  return (
    <div className="min-w-96" onClick={(e) => e.stopPropagation()}>
      <ExistingFilters
        column_list={column_list}
        row_filters={row_filters}
        filterOptions={filterOptions}
      />
      <div>
        <FilterFields
          showNoFiltersMessage={false}
          filterForm={rowFilters}
          filterOptions={filterOptions}
          handleFormUpdate={handleFormUpdate}
          handleFilterRemove={handleFilterRemove}
          cols={(column_list || []).map((i) => ({
            id: i.data_field,
            name: getColumnDisplayName(i),
          }))}
        />
      </div>
      <div>
        <IconButton
          overrideClasses="max-h-min max-w-max py-2"
          icon={<PlusIcon width={20} className="fill-purpleHover" />}
          text={"New filter"}
          variant="outline"
          onClick={() => {
            setRowFilters((prev) => [
              ...prev,
              {
                id: Math.random().toString(),
                name: "",
                key: "",
                type: "",
                value: "",
              },
            ]);
          }}
        />
      </div>
      {rowFilters.length > 0 && (
        <div className="mt-12 flex justify-center">
          <IconButton
            overrideClasses="max-w-max"
            icon={<FunnelIcon width={20} className="fill-white" />}
            text={"Run filter"}
            variant="fill"
            onClick={() => {
              submit(rowFilters);
            }}
          />
        </div>
      )}
    </div>
  );
};

function ExistingFilters({
  row_filters,
  column_list,
  filterOptions,
}: {
  row_filters: { [id: string]: RowFilter };
  column_list: Column[];
  filterOptions: Array<{
    id: FilterFormField["type"];
    name: string;
  }>;
}) {
  const [disableFilterLoadingIds, setDisableFilterLoadingIds] = useState<
    string[]
  >([]);
  const { chatId } = useParams();
  const { user } = useAuth();

  const handleToggle = (id: string) => {
    console.log(id);
    setDisableFilterLoadingIds((prev) => [...prev, id]);
    backend_services
      .fetchProtectedData(
        `/chat/${chatId}/table/${row_filters[id].is_active ? "disable_filter" : "enable_filter"}?filter_id=${id}`,
        user?.getIdToken(),
        undefined,
        "POST",
      )
      .then((res) => {
        console.log(res);
        toast.success("Filter disabled successfully.");
      })
      .catch(() => {
        toast.error("Failed to disable filter. Please try again.");
      })
      .finally(() => {
        setDisableFilterLoadingIds((prev) =>
          prev.filter((thisId) => thisId !== id),
        );
      });
  };

  const handleDelete = async (id: string) => {
    try {
      await toast.promise(
        backend_services.fetchProtectedData(
          `/chat/${chatId}/table/delete_filter/${id}`,
          user?.getIdToken(),
          undefined,
          "DELETE",
        ),
        {
          pending: "Deleting filter...", // Loader message
          success: "Filter deleted successfully!", // Success message
          error: "Something went wrong while deleting the filter.", // Error message
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="min-w-96" onClick={(e) => e.stopPropagation()}>
      <div className="pb-2 font-semibold">Applied filters</div>
      {Object.entries(row_filters).length < 1 ? (
        <div>No filters applied</div>
      ) : disableFilterLoadingIds.length > 0 ? (
        <div className="flex justify-center">
          <SimpleSpinner radius={20} overrideClasses="border-black" />
        </div>
      ) : (
        <ul className="m-0 flex list-none flex-col gap-2 p-0">
          {Object.entries(row_filters)
            .sort((a, b) => String(b[1].key).localeCompare(String(a[1].key)))
            .map(([id, f]) => {
              return (
                <li
                  key={id}
                  className="flex flex-row items-center justify-between gap-2"
                >
                  <div className="flex min-w-max flex-row items-center gap-2">
                    <span className="line-clamp-1 rounded-md  border border-primary px-1.5 py-0.5">
                      {column_list.find((c) => c.data_field === f.key)
                        ? getColumnDisplayName(
                            column_list.find(
                              (c) => c.data_field === f.key,
                            ) as Column,
                          )
                        : ""}
                    </span>
                    <span className="line-clamp-1 rounded-md bg-slate-200 px-1 py-0.5">
                      {filterOptions.find((o) => o.id === f.type)?.name}
                    </span>
                    <span className="border-1 line-clamp-1 rounded-md border border-primary px-1 py-0.5">
                      {f.value.toString()}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <Switcher3
                      invertCheckedUi={true}
                      isChecked={row_filters[id].is_active}
                      onChange={() => {
                        handleToggle(id);
                      }}
                    />
                    <DeleteIcon
                      height={30}
                      width={30}
                      className="cursor-pointer"
                      onClick={() => handleDelete(id)}
                    />
                  </div>
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
}
function ColumnOptions({
  columns,
}: {
  columns: Column[];
  columnFilters?: any[];
}) {
  const { user } = useAuth();
  const { chatId } = useParams();
  const [reorderLoading, setReorderLoading] = useState(false);
  function handleReorder(newCols: Column[]) {
    function saveUpdates(updates: { [key: string]: number }) {
      setReorderLoading(true);
      backend_services
        .fetchProtectedData(
          `/chat/${chatId}/column/reorder`,
          user?.getIdToken(),

          undefined,
          "POST",
          {
            updates,
          },
        )
        .then((res) => {
          // console.log(res);
        })
        .finally(() => {
          setReorderLoading(false);
        });
    }
    function commitUpdates(items: Column[]) {
      const updates: { [key: string]: number } = {};
      items.forEach((item, index) => {
        const targetItem = columns.find((c) => c.id === item.id);
        if (targetItem && columns.indexOf(targetItem) !== index) {
          updates[targetItem.id] = index;
        }
      });
      console.log(updates);
      if (Object.keys(updates).length > 0) {
        saveUpdates(updates);
      }
    }
    commitUpdates(newCols);
  }
  if (!columns) {
    return <></>;
  }
  const handleDragStart = (e: any, index: number) => {
    e.dataTransfer.setData("drag-index", index);
  };

  const handleDrop = (e: any, dropIndex: number) => {
    const dragIndex = Number(e.dataTransfer.getData("drag-index"));
    console.log({ dragIndex, dropIndex });
    if (dragIndex === dropIndex) {
      return;
    }
    const itemDragged = columns[dragIndex];
    const remainingItems = columns.filter((_, index) => index !== dragIndex);
    remainingItems.splice(dropIndex, 0, itemDragged);
    console.log(remainingItems);
    handleReorder(remainingItems);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault(); // Necessary for the onDrop to fire
  };
  return (
    <ul className="relative m-0 p-0">
      {columns.map((column, index) => (
        <ColumnWithActions
          key={column.id}
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
          onDrop={(e) => handleDrop(e, index)}
          onDragOver={handleDragOver}
          column={column}
        />
      ))}
      {reorderLoading && (
        <>
          <div className="absolute inset-0 h-full rounded bg-slate-200 bg-opacity-80 backdrop-blur-sm transition-all duration-300 ">
            <div className="sticky top-1/2 z-10 flex items-center justify-center opacity-100 ">
              <div className="flex flex-col items-center justify-center gap-2">
                <SimpleSpinner radius={20} overrideClasses="border-black" />
                <span>Saving...</span>
              </div>
            </div>
          </div>
        </>
      )}
    </ul>
  );
}
interface ColumnActionProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  column: Column;
}
function ColumnWithActions({ column, ...props }: ColumnActionProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const { user } = useAuth();
  const { chatId } = useParams();
  const [editableValue, setEditableValue] = useState(
    getColumnDisplayName(column),
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEditableValue(event.target.value);
  };

  const handleBlur = () => {
    updateColumnName(column, editableValue);
  };

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      updateColumnName(column, editableValue);
    }
  }

  const handleColumnHideToggle = async (columnId: string) => {
    const newToggleState = !column.hidden;
    try {
      setEditLoading(true);
      const response = await backend_services.fetchProtectedData(
        `/chat/${chatId}/column/${columnId}/toggle?hide=${newToggleState}`,
        user?.getIdToken(),
        undefined,
        "POST",
      );
      if (response.status !== 200) {
        throw new Error("Failed to save column toggle");
      }
    } catch (error) {
      console.error("Error saving column filter:", error);
    } finally {
      setEditLoading(false);
    }
  };
  function updateColumnName(column: Column, newName: string) {
    setIsEditing(false);
    if (!newName || getColumnDisplayName(column) === newName) {
      return;
    }
    setEditLoading(true);
    backend_services
      .fetchProtectedData(
        `/chat/${chatId}/column/rename`,
        user?.getIdToken(),
        undefined,
        "POST",
        {
          column_id: column.id,
          new_name: newName,
        },
      )
      .then((res) => {
        console.log(res);
      })
      .finally(() => {
        setEditLoading(false);
      });
  }

  return (
    <button
      onClick={() => {}}
      className="flex w-full gap-2 rounded pl-2.5 transition-all duration-300 hover:bg-grey-600"
      {...props}
    >
      <div className="group relative z-0 line-clamp-1 flex max-w-full flex-grow items-center gap-2 py-2 text-left">
        <TableColumnIcon column={column} />
        {editLoading ? (
          <SimpleSpinner radius={20} overrideClasses="border-black" />
        ) : isEditing ? (
          <input
            className="h-full w-full px-3 py-4 outline-none"
            type="text"
            value={
              typeof editableValue === "string"
                ? editableValue
                : JSON.stringify(editableValue)
            }
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            autoFocus
          />
        ) : (
          <p className="truncate">{getColumnDisplayName(column)}</p>
        )}

        {!isEditing && !editLoading && (
          <div className="invisible absolute right-0 flex h-full gap-1 py-1 opacity-0 transition-all duration-300 group-hover:visible group-hover:opacity-100">
            <Switcher3
              isChecked={!!column.hidden}
              onChange={(e) => {
                e.stopPropagation();
                handleColumnHideToggle(column.id);
              }}
            />

            <IconButton
              overrideClasses="z-10 h-full gap-0 bg-white px-2"
              icon={<PencilIcon width={20} />}
              text={<></>}
              variant="outline"
              onClick={(e) => {
                setIsEditing(true);
                e.preventDefault();
                e.stopPropagation();
              }}
            />
            <ColumnDeleteButton column={column} />
          </div>
        )}
      </div>
    </button>
  );
}
export function TableColumnIcon({ column }: { column: Column }) {
  return column.type === "tool_column" ? (
    <img width={20} src={column.tool.icon} alt={column.tool.name} />
  ) : column.type === "custom_tool_column" ? (
    <div className="h-6 w-6">
      <StarsIcon fill="#e08588" />
    </div>
  ) : (
    <KurationSmallIcon width={20} height={15} />
  );  
}
function ColumnDeleteButton({ column }: { column: Column }) {
  const { chatId } = useParams();
  const { user } = useAuth();
  const [deleteLoading, setDeleteLoading] = useState(false);
  if (!chatId || !user) {
    return <></>;
  }

  function handleDeleteColumn() {
    setDeleteLoading(true);
    backend_services
      .fetchProtectedData(
        `/chat/${chatId}/column/delete`,
        user?.getIdToken(),
        undefined,
        "DELETE",
        {
          column_id: column.id,
        },
      )
      .then((res) => {
        console.log(res);
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  }

  return (
    <IconButton
      overrideClasses="z-10 h-full gap-0  bg-white px-2"
      icon={
        deleteLoading ? (
          <SimpleSpinner radius={20} overrideClasses="border-black" />
        ) : (
          <DeleteIcon width={20} />
        )
      }
      text={<></>}
      variant="outline"
      onClick={(e) => {
        handleDeleteColumn();

        e.preventDefault();
        e.stopPropagation();
      }}
    />
  );
}
function chatOptions() {
  return [
    <IconItem
      onClick={() => {
        console.log("share clicked");
      }}
      text="Share"
      icon={<ShareIcon />}
    />,
    <IconItem
      onClick={() => {
        console.log("share clicked");
      }}
      text="Rename"
      icon={<EditIcon />}
    />,
    <IconItem
      color="text-error"
      onClick={() => {
        console.log("share clicked");
      }}
      text="Delete"
      icon={<DeleteIcon />}
    />,
  ];
}
