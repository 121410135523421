import React from "react";
import SearchIcon from "src/components/svgs/Search";

interface LinkedInPostLikerFormProps {
  postURL: string;
  setPostURL: (value: string) => void;
  sessionCookies: string;
  setSessionCookies: (value: string) => void;
  onSubmit: () => void;
}

const LinkedInPostLikerForm: React.FC<LinkedInPostLikerFormProps> = ({
  setPostURL,
  postURL,
  setSessionCookies,
  sessionCookies,
  onSubmit,
}) => {
  return (
    <div className="relative grid gap-6">
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <p>LinkedIn Post URL</p>
        </div>
        <textarea
          value={postURL}
          placeholder={"https://www.linkedin.com/posts/your-post-id"}
          onChange={(e) => setPostURL(e.target.value)}
          className="peer inline-flex max-h-14 w-full resize-none items-center justify-center gap-2.5 rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-[2px_2px_20px_0px_#00000014] outline-none hover:border-purple md:pl-4"
        ></textarea>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <p>Session Cookie</p>
        </div>
        <textarea
          value={sessionCookies}
          placeholder={"Insert your session cookies here"}
          onChange={(e) => setSessionCookies(e.target.value)}
          className="peer inline-flex max-h-14 w-full resize-none items-center justify-center gap-2.5 rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-[2px_2px_20px_0px_#00000014] outline-none hover:border-purple md:pl-4"
        ></textarea>
      </div>
      <div className="flex justify-between">
        <div className="items-center">
          Reminder: Ensure you have a valid LinkedIn session cookie. This is
          required to fetch liker data from posts.
        </div>
        <button
          onClick={onSubmit}
          className="flex items-center gap-2 rounded bg-purple p-[7px] text-white outline-none hover:bg-purpleHover focus:bg-purpleHover"
        >
          <p>Fetch Likers</p>
          <div>
            <SearchIcon height="20" width="20" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default LinkedInPostLikerForm;
