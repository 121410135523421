import { InitialListItem } from "src/models/InitialList";
import GoogleMapPin from "../svgs/GoogleMapPin";
import UploadIcon from "../svgs/Upload";
import HandIcon from "../svgs/Hand";
import WebIcon from "../svgs/WebIcon";
import FilterIcon from "../svgs/Filter";
// import { BriefcaseIcon } from "../svgs/BriefcaseIcon";
// import { NewspaperIcon } from "../svgs/NewpaperIcon";
// import SearchIcon from "../svgs/Search";
// import { OfficeBuildingIcon } from "../svgs/OfficeIcon";

const items: InitialListItem[] = [
  {
    _id: "6",
    name: "csvFileUploader",
    description: "Upload CSV file",
    subtitle: "Import and curate existing lists",
    icon: <UploadIcon />,
  },
  {
    _id: "1",
    name: "googleMap",
    description: "Target customers in specific areas",
    subtitle: "Extract businesses using GoogleMaps",
    icon: <GoogleMapPin />,
  },
  {
    _id: "2",
    name: "salesNavSearch",
    description: "Scrape Linkedin Sales Navigator",
    subtitle: "Export long list of leads from a search URL",
    icon: (
      <img
        src="https://uploads-ssl.webflow.com/6697d10a891d3b0b8f8a6628/6698c438c5e7f16548da414d_linkedin-sales-navigator.jpg"
        alt="LinkedIn"
        className="h-12 w-12" // Updated for responsiveness
      />
    ),
  },
  {
    _id: "3",
    name: "postCommenter",
    description: "Fetch LinkedIn Post Commenters",
    subtitle: "Extract profiles of all commenters from a LinkedIn post URL",
    icon: (
      <img
        src="https://uploads-ssl.webflow.com/6697d10a891d3b0b8f8a6628/6698c438c5e7f16548da414d_linkedin-sales-navigator.jpg"
        alt="LinkedIn Post"
        className="h-12 w-12" // Updated for responsiveness
      />
    ),
  },
  {
    _id: "4",
    name: "postLiker",
    description: "Fetch LinkedIn Post Likers",
    subtitle: "Extract profiles of all likers from a LinkedIn post URL",
    icon: (
      <img
        src="https://uploads-ssl.webflow.com/6697d10a891d3b0b8f8a6628/6698c438c5e7f16548da414d_linkedin-sales-navigator.jpg"
        alt="LinkedIn Post"
        className="h-12 w-12" // Updated for responsiveness
      />
    ),
  },
  // {
  //   _id: "3",
  //   name: "GoogleJob",
  //   description: "Scrape JobBoards",
  //   subtitle: "Find companies currently hiring for specific roles",
  //   icon: <NewspaperIcon />,
  // },
  // {
  //   _id: "4",
  //   name: "parentCompanyLookup",
  //   description: "Uncover potential business connections",
  //   subtitle: "Find all subsidiaries of parent companies",
  //   icon: <BriefcaseIcon />,
  // },
  // {
  //   _id: "5",
  //   name: "companyDetailsLookup",
  //   description: "Get deeper insights into businesses",
  //   subtitle: "Enrich details of a long list companies",
  //   icon: <OfficeBuildingIcon />,
  // },
  {
    _id: "7",
    name: "startManually",
    description: "Start with standard filters",
    subtitle: "Get list based on location and industry",
    icon: <FilterIcon />,
  },
  // {
  //   _id: "8",
  //   name: "peopleFinder",
  //   description: "Identify individuals by their roles",
  //   subtitle: "Find people from company websites",
  //   icon: <SearchIcon />,
  // },
  {
    _id: "9",
    name: "indeedJobScraper", // Added for Indeed Job Scraper
    description: "Scrape Indeed job listings",
    subtitle: "Find job listings from Indeed based on title and location",
    icon: (
      <img
        src="https://cdn.prod.website-files.com/64acc1670486f7df09be2423/6790a92b6b005595027ad1a6_Indeed_2021_Icon_RGB_Blue.png"
        alt="Indeed Job Scraper"
        className="h-10 w-10 object-contain" // Styling to match the others
      />
    ),
  },
  {
    _id: "10",
    name: "linkedinJobScrapper",
    description: "Scrape Linkedin job listings",
    subtitle: "Find job listings from Linkedin",
    icon: (
      <img
        src="https://store-images.s-microsoft.com/image/apps.1719.9007199266245564.44dc7699-748d-4c34-ba5e-d04eb48f7960.abf46174-2d32-4f53-a6cd-644d5b2be452"
        alt="Linkedin Job Scraper"
        className="h-12 w-12 object-contain"
      />
    ),
  },
  {
    _id: "11",
    name: "webToTable",
    description: "Web to table",
    subtitle: "Import directories from web",
    icon: <WebIcon />,
  },
  {
    _id: "12",
    name: "lookalikesearch",
    description: "Lookalike Search",
    subtitle: "Find Companies that are not on LinkedIn or Google Maps",
    icon: "https://cdn.prod.website-files.com/64acc1670486f7df09be2423/678749bbea25a659c18fc93f_panda-logo.png",
  },
  {
    _id: "13",
    name: "manualCompanies",
    description: "Add companies manually",
    subtitle: "Quickly research a few companies",
    icon: <HandIcon />,
  },
];

export default items;
