import { InitialListItem } from "src/models/InitialList";
import { useState, useRef, useEffect } from "react";
import GoogleMapList from "./GoogleMapList";
import SalesNavSearchList from "./SalesNavSearchList";
import LinkedInPostCommenter from "./LinkedInPostCommenter";
import GoogleJobList from "./GoogleJobList";
import ParentCompanyLookup from "./ParentCompanyLookup";
import CompanyDetailsLookup from "./CompanyDetailsLookup";
import CSVFileUploader from "./CSVFileUploader";
import StartManually from "./StartManually";
import PeopleFinder from "./PeopleFinder";
import IndeedJobScraper from "./IndeedJobScraper";
import { useLocation } from "react-router-dom";
import LinkedinJobScrapper from "./LinkedinJobScrapper";
import WebToTable from "./WebToTable";
import LookALikeSearch from "./LookALikeSearch";
import ChromeIcon from "../svgs/ChromeIcon";
import ManualCompanies from "./ManualCompanies";

import LinkedInPostLiker from "./LinkedInPostLiker";

export function InitialLists({ items }: { items: InitialListItem[] }) {
  return (
    <div className="grid h-full grid-rows-[min-content,1fr] gap-10">
      <p className="text-4xl font-normal text-neutral-900">
        Initial List Builder
      </p>
      <p className="text-lg font-light text-neutral-600">
        What list do you want to curate? First, build an initial list from the
        best sources, select one of the builder
      </p>
      <div className="flex flex-wrap justify-start gap-4">
        {/* Render the general cards */}
        {items.map((item) => (
          <InitialCard key={item._id} item={item} />
        ))}

        {/* Add the "bookACall" card separately */}
        <div>
          <button
            className="flex flex-col items-center justify-center gap-4 rounded-lg border-b-4 border-gray-200 bg-gray-200 p-4 transition-all hover:shadow-lg"
            onClick={() =>
              window.open(
                "https://app.apollo.io/#/meet/aurelien_vasinis_talk-to-me/30-min-2",
                "_blank",
                "noopener,noreferrer",
              )
            }
            style={{ width: "300px", height: "180px" }}
          >
            <div className="flex flex-col items-center gap-2">
              <div className="flex h-16 w-16 items-center justify-center rounded-full bg-white">
                <img
                  src="https://cdn.prod.website-files.com/64acc1670486f7df09be2423/674673b0e194887ede0dca04_ceo-aurelien-online.svg"
                  alt="Book a Call"
                  className="h-full w-full rounded-full object-cover"
                />
              </div>
              <div className="flex flex-col items-center justify-center text-center">
                <div className="text-lg font-normal text-gray-900">
                  Book a call
                </div>
                <div className="text-sm font-light text-gray-600">
                  Didn't find what you need? Book a call with Aurelien!
                </div>
              </div>
            </div>
          </button>
        </div>
        <div>
          <button
            className="flex flex-col items-center justify-center gap-4 rounded-lg border-b-4 border-gray-200 bg-primary bg-opacity-20 p-4 transition-all hover:shadow-lg"
            onClick={() =>
              window.open(
                "https://chromewebstore.google.com/detail/kuration-ai-extension-lin/ddjmcjneljfmljlmlmclcbhefdlbaaoa",
                "_blank",
                "noopener,noreferrer",
              )
            }
            style={{ width: "300px", height: "180px" }}
          >
            <div className="flex flex-col items-center gap-2">
              <div className="flex h-16 w-16 items-center justify-center rounded-full bg-white">
                <ChromeIcon className="w-10" />
              </div>
              <div className="flex flex-col items-center justify-center text-center">
                <div className="text-lg font-normal text-gray-900">
                  Install Chrome Extension
                </div>
                <div className="text-sm font-light text-gray-600">
                  Lets you create sophisticated research workflows with just a
                  few clicks
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

const InitialCard = ({ item }: { item: InitialListItem }) => {
  const [showQueryBox, setShowQueryBox] = useState(false);
  const queryBoxRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  function handleClick() {
    setShowQueryBox(true);
  }

  function handleClose() {
    setShowQueryBox(false);
  }

  function handleClickOutside(event: MouseEvent) {
    if (
      queryBoxRef.current &&
      !queryBoxRef.current.contains(event.target as Node)
    ) {
      handleClose();
    }
  }

  useEffect(() => {
    if (showQueryBox) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    if (
      location.pathname === "/initial-list-builder/csvFileUpload" &&
      item.name === "csvFileUploader"
    ) {
      setShowQueryBox(true);
    }
  }, [location.pathname, item.name]);

  return (
    <div>
      <button
        className="flex flex-col items-center justify-center gap-4 rounded-lg border-b-4 border-gray-200 bg-gray-200 p-4 transition-all hover:shadow-lg"
        onClick={handleClick}
        style={{ width: "300px", height: "180px" }}
      >
        <div className="flex flex-col items-center gap-2">
          <div className="flex h-16 w-16 items-center justify-center rounded-full bg-white p-2">
            {item.name === "salesNavSearch" ? (
              <img
                src="https://uploads-ssl.webflow.com/6697d10a891d3b0b8f8a6628/6698c438c5e7f16548da414d_linkedin-sales-navigator.jpg"
                alt="Sales Navigator"
                className="h-full w-full rounded-full object-cover"
              />
            ) : typeof item.icon === "string" ? (
              <img
                src={item.icon}
                alt={item.name}
                className="h-full w-full rounded-full object-cover"
              />
            ) : (
              <div className="flex h-full w-full items-center justify-center overflow-hidden rounded-full">
                {item.icon}
              </div>
            )}
          </div>
          <div className="flex flex-col items-center justify-center text-center">
            <div className="text-lg font-normal text-gray-900">
              {item.description}
            </div>
            <p className="card-subtitle"></p>
            <div className="text-sm font-light text-gray-600">
              {item.subtitle}
            </div>
          </div>
        </div>
      </button>

      {showQueryBox && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div
            ref={queryBoxRef}
            className="relative rounded-lg bg-white p-6 shadow-lg"
          >
            {item.name === "googleMap" && (
              <GoogleMapList item={item} handleClose={handleClose} />
            )}
            {item.name === "GoogleJob" && (
              <GoogleJobList item={item} handleClose={handleClose} />
            )}
            {item.name === "salesNavSearch" && (
              <SalesNavSearchList handleClose={handleClose} />
            )}
            {item.name === "postCommenter" && (
              <LinkedInPostCommenter handleClose={handleClose} />
            )}
            {item.name === "postLiker" && (
              <LinkedInPostLiker handleClose={handleClose} />
            )}
            {item.name === "parentCompanyLookup" && (
              <ParentCompanyLookup item={item} handleClose={handleClose} />
            )}
            {item.name === "companyDetailsLookup" && (
              <CompanyDetailsLookup item={item} handleClose={handleClose} />
            )}
            {item.name === "csvFileUploader" && (
              <CSVFileUploader item={item} handleClose={handleClose} />
            )}
            {item.name === "startManually" && (
              <StartManually item={item} handleClose={handleClose} />
            )}
            {item.name === "peopleFinder" && (
              <PeopleFinder item={item} handleClose={handleClose} />
            )}
            {item.name === "indeedJobScraper" && (
              <IndeedJobScraper handleClose={handleClose} initialSearchUrl="" />
            )}
            {item.name === "linkedinJobScrapper" && (
              <LinkedinJobScrapper
                handleClose={handleClose}
                numberOfJobs={10}
                initialJobTitle=""
              />
            )}
            {item.name === "webToTable" && (
              <WebToTable handleClose={handleClose} />
            )}
            {item.name === "lookalikesearch" && (
              <LookALikeSearch
                initialDomain=""
                initialkeyword=""
                handleClose={handleClose}
              />
            )}
            {item.name === "manualCompanies" && <ManualCompanies />}

            <button
              className="absolute right-2 top-2 text-gray-600 hover:text-gray-900"
              onClick={handleClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
