import React, {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import FullScreenIcon from "src/components/svgs/FullScreen";
import ChatMessage from "./ChatMessage";
import InputContainer from "../../components/elements/InputContainer";
import { CreateAndSendMessage, Message } from "../../models/data";
import Modal from "../../components/elements/Modal";
import ContactForm from "../../components/ContactForm";
import { useAuth } from "../../context/AuthContext";
import backend_service from "../../services/backend_service";

import { toast } from "react-toastify";
import Spinner from "../../components/elements/Spinner";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import RequestStatus from "../RequestUpdates/RequestStatus";
import ChatToolbar from "../toolbar/ChatToolbar";
import { scrollToElement } from "../../components/utils";
import { useStateContext } from "../../context/StateContext";
import { PopupButton } from "@typeform/embed-react";
import useChatUpdates from "../../hooks/useChatUpdates";
import { useTutorialMode } from "../../hooks/useTutorialMode";
import { FiltersLibrary } from "../filters/FiltersLibrary";
import { DataPointExplorer } from "../dataPointExplorer/DataPointExplorer";
import CustomModal from "src/components/elements/CustomModel";
import { ObjectiveModal } from "src/components/research/Objectives";
import { ObjectiveGenerated } from "src/models/updates";
import { MinimizeIcon } from "src/components/svgs/Minimize";

const ChatContainer = forwardRef(
  (props, ref: Ref<{ createAndSendMessage: CreateAndSendMessage }>) => {
    const [socket, setSocket] = useState<WebSocket | null>();
    const {
      chatType,
      filtersOpen,
      dpeState,
      chatOpen,
      chatFullScreen,
      setChatFullScreen,
    } = useStateContext();
    const [functionModal, setFunctionModal] = useState({
      isOpen: false,
      objective: "",
      requirements: [""],
    });
    const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);
    const { chatId: currentOpenChat } = useParams();
    const { tutorialOpen } = useTutorialMode();
    const [searchParams] = useSearchParams();
    const preventReload = searchParams.get("preventReload") === "true";
    const chatUpdates = useChatUpdates();
    const objective_update = chatUpdates.find(
      (update) => update.type === "objective_generated",
    ) as ObjectiveGenerated | undefined;
    const [messages, setMessages] = useState<Message[]>([]);

    const [isMessageStreaming, setIsMessageStreaming] = useState(false);
    const messagesContainer = useRef(null);

    const { user } = useAuth();
    const navigator = useNavigate();
    const [chatIsLoading, setChatLoading] = useState(false);
    const [inputContainerHeight, setInputContainerHeight] =
      useState<number>(87);
    const inputContainertRef = useCallback(
      (node: HTMLDivElement) => {
        if (!node) return;
        const resizeObserver = new ResizeObserver(() => {
          let newHeight = node.getBoundingClientRect().height;
          if (newHeight !== inputContainerHeight) {
            setInputContainerHeight(newHeight);
          }
        });
        resizeObserver.observe(node);
      },
      [inputContainerHeight],
    );
    const heightVariants: { [key: number]: string } = {
      87: "h-[calc(100vh-87px)]",
      111: "h-[calc(100vh-111px)]",
      135: "h-[calc(100vh-135px)]",
      159: "h-[calc(100vh-159px)]",
      183: "h-[calc(100vh-183px)]",
      207: "h-[calc(100vh-207px)]",
      231: "h-[calc(100vh-231px)]",
      255: "h-[calc(100vh-255px)]",
      279: "h-[calc(100vh-279px)]",
      303: "h-[calc(100vh-303px)]",
    };

    const useCasePopupRef = useRef();
    const openUseCasePopup = () => (useCasePopupRef.current as any)?.open();
    useEffect(() => {
      if (process.env.NODE_ENV === "development") {
        console.log({ preventReload });
      }
      // if (preventReload) {
      //   console.log("Logging prevented");
      //   return;
      // }
      const abortController = new AbortController();

      setMessages([]);
      if (user && currentOpenChat) {
        console.log("loading started");
        setChatLoading(true);
        backend_service
          .fetchProtectedData(
            `/get_chat_messages/?chat_id=${currentOpenChat}`,
            user.getIdToken(),
            abortController.signal,
          )
          .then((res) => {
            return res.json();
          })
          .then((res_json) => {
            setMessages(
              ((res_json["messages"] ?? []) as Message[]).filter((m) => {
                return (
                  m.role === "user" ||
                  (m.role === "assistant" && m.content != null) ||
                  (m.role === "logging" && m.type === "user_list")
                );
              }),
            );
            setChatLoading(false);
            setTimeout(
              () => scrollToElement({ containerRef: messagesContainer }),
              10,
            );
          })
          .catch((error: Error) => {
            // console.log(error);
            if (!error.message.includes("aborted")) {
              toast("Failed to fetch your chats.", {
                toastId: "chatsFetchFail",
              });
              setTimeout(
                () => scrollToElement({ containerRef: messagesContainer }),
                10,
              );

              setChatLoading(false);
            }
          });
      }
      return () => {
        abortController.abort();
        setChatLoading(false);
      };
    }, [currentOpenChat, preventReload, user]);

    const handleSocketMessageEventCallback = useCallback(
      function handleSocketMessageEvent(ev: MessageEvent) {
        let myStreamStringChunk = ev.data as string;
        if (
          myStreamStringChunk.startsWith("##OPENEND##ERROR_RECHARGE_REQUIRED:")
        ) {
          toast("You ran out of credits. Please recharge first and come back.");
          setIsMessageStreaming(false);
          navigator("/profile/account");
        } else if (myStreamStringChunk.startsWith("##OPENEND##ERROR_TOKEN:")) {
          user?.getIdToken(true);
          toast("Login expired. Please login again");
          setIsMessageStreaming(false);
        } else if (myStreamStringChunk.startsWith("##OPENEND##ERROR:")) {
          myStreamStringChunk = myStreamStringChunk.replace(
            "##OPENEND##ERROR:",
            "",
          );
          toast.error(`An error occurred: ${myStreamStringChunk}`);
          setIsMessageStreaming(false);
          alert(`An error occurred ${myStreamStringChunk}`);
        } else if (myStreamStringChunk.startsWith("##OPENEND##FUNC:")) {
          let func_call = myStreamStringChunk.replace("##OPENEND##FUNC:", "");
          const func_call_json = JSON.parse(func_call);
          setFunctionModal({
            isOpen: true,
            objective:
              func_call_json["function_call"]["arguments"]["objective"],
            requirements:
              func_call_json["function_call"]["arguments"]["requirements"],
          });
          setIsMessageStreaming(false);
        } else if (
          myStreamStringChunk.startsWith("##OPENEND##FORM_SUCESSFUL")
        ) {
          toast.success("All done 🥳");
          setIsMessageStreaming(false);
        } else if (myStreamStringChunk.startsWith("##OPENEND##USECASEWRONG")) {
          setIsMessageStreaming(false);
          // window.open("https://kurationai.typeform.com/notdoable", "_blank");
          openUseCasePopup();
        } else if (myStreamStringChunk.startsWith("##OPENEND##")) {
          let open_most_recent_by_id = myStreamStringChunk.split("_")[1];
          if (open_most_recent_by_id && open_most_recent_by_id !== "LLM") {
            navigator(
              "/chat/" + open_most_recent_by_id + "?preventReload=true",
            );
          }

          setIsMessageStreaming(false);
        } else {
          setMessages((prev) => {
            let newMessages = [...prev];
            let lastMess = newMessages[newMessages.length - 1];
            newMessages[newMessages.length - 1] = {
              ...lastMess,
              content: lastMess?.content + myStreamStringChunk,
            };
            return newMessages;
          });
        }
      },
      [navigator, user],
    );

    useEffect(() => {
      if (socket && socket.readyState === socket.OPEN) {
        return;
      }
      let ws: WebSocket;
      try {
        // setConnectionState("connecting");
        ws = new WebSocket(backend_service.ws_url);

        if (ws) {
          ws.onmessage = (ev: MessageEvent) => {
            handleSocketMessageEventCallback(ev);
          };
          ws.onerror = (ev: Event) => {
            console.log(`socket error ${ev}`, ev);
            setSocket(null);
          };
          ws.onclose = (ev: CloseEvent) => {
            console.log(`socket error ${ev}`, ev);
            setSocket(null);
          };
          ws.onopen = (ev: Event) => {
            setSocket(ws);
          };
        }
      } catch (error) {
        console.log(`socket error ${error}`, error);
        alert(`socket error ${error}`);
      }
      return () => {};
    }, [handleSocketMessageEventCallback, socket]);

    const addUserMessage = (
      userMess: string,
      userUploadedJson?: Record<string, string>[] | null,
    ) => {
      if (isMessageStreaming) {
        console.log("A message is already in progress.");
        return;
      }
      const listMess: Message = {
        role: "logging",
        type: "user_list",
        data: userUploadedJson ? userUploadedJson : [],
        time: new Date().toLocaleTimeString(),
        content: null,
      };
      setMessages((prev) => {
        return [
          ...prev,
          ...(userUploadedJson ? [listMess] : []),
          {
            content: userMess,
            role: "user",
            time: new Date().toLocaleTimeString(),
          },
          {
            content: "",
            role: "assistant",
            time: new Date().toLocaleTimeString(),
          },
        ];
      });
    };
    const createAndSendMessage: CreateAndSendMessage = async (
      messageText: string,
      userUploadedJson?: Record<string, string>[] | null,
      hiddenMessage: boolean = false,
      type: "contact_form" | "manual_search" | "prompt" = "prompt",
      visualText?: string,
    ) => {
      let newChatType = chatType;
      if (userUploadedJson) {
        newChatType = "upload";
      }
      if (!hiddenMessage) addUserMessage(messageText, userUploadedJson);
      if (hiddenMessage && visualText && visualText?.length > 0) {
        addUserMessage(visualText, userUploadedJson);
      }
      if (type === "contact_form" || type === "manual_search") {
        //for contact form, the messageText would actual be stringified json,
        // so we dent wanna restringing it so we first parse it
        messageText = JSON.parse(messageText);
      }
      let data_to_send = JSON.stringify({
        type: type,
        data: messageText,
        chat_id: currentOpenChat,
        chat_type: newChatType,
        ...(userUploadedJson ? { uploaded_list: userUploadedJson } : {}),
        idToken: await user?.getIdToken(),
      });

      try {
        sendMessage(data_to_send);
      } catch (error) {
        console.log(`socket error ${error}`, error);
        alert(`socket error ${error}`);
      }
      // if (!hiddenMessage) createStreamingMessage();
    };
    function sendMessage(text: string) {
      try {
        if (socket && socket.readyState === socket.OPEN) {
          socket?.send(text);
          setIsMessageStreaming(true);
          console.log("MESSAGE IS NOW STREAMING");
        } else {
          toast.error("Problem connecting to server. Refresh page.");
        }
      } catch (error) {
        console.log(`socket error ${error}`, error);
        // alert(`socket error ${error}`);
      }
    }

    useImperativeHandle(ref, () => ({
      createAndSendMessage,
    }));
    // console.log(
    //   functionModal.isOpen &&
    //     objective_update &&
    //     objective_update.status !== "completed",
    // );
    return (
      <div className="relative h-full flex-grow">
        <button
          onClick={(e) => {
            setChatFullScreen(!chatFullScreen);
          }}
          className="fixed top-3 z-50 ml-2 p-1"
        >
          {chatFullScreen ? <MinimizeIcon /> : <FullScreenIcon />}
        </button>

        {((!chatIsLoading && messages.length === 0) || tutorialOpen) && (
          <div
            className={`h-full ${tutorialOpen && messages.length > 0 ? "md:hidden" : ""}`}
          >
            <ChatToolbar createAndSendMessage={createAndSendMessage} />
          </div>
        )}
        {filtersOpen && currentOpenChat && !tutorialOpen && (
          <div className="h-full ">
            <FiltersLibrary />
          </div>
        )}
        {dpeState.isOpen && currentOpenChat && !tutorialOpen && (
          <div className="absolute inset-0 h-full bg-white">
            <DataPointExplorer dpeState={dpeState} />
          </div>
        )}
        {!filtersOpen && !dpeState.isOpen && (
          <div
            className={`flex flex-col ${tutorialOpen ? "hidden md:block" : ""}`}
          >
            {/* {chatUpdates.length > 0 && (
              <ChatStatusIndictor
                latestChatUpdate={chatUpdates[chatUpdates.length - 1]}
              />
            )} */}
            {chatIsLoading && (
              <Spinner
                text=""
                onWhiteBackground
                // styleClasses={[`${sidebarOpen ? "md:ml-[300px]" : ""}`]}
              />
            )}
            {messages.length > 0 && (
              <div
                className={`flex flex-grow flex-col overflow-auto overflow-y-auto transition-transform duration-300 ease-out ${heightVariants[inputContainerHeight]} bg-white p-5 pt-0 md:px-6`}
              >
                {messages.map((mess, index) => (
                  <ChatMessage
                    key={index.toString() + mess.time}
                    message={mess}
                    userName={
                      mess.role === "user" || mess.type === "user_list"
                        ? `${user?.displayName ?? ""}`
                        : "Kuration AI"
                    }
                    isLast={messages.length - 1 === index}
                  />
                ))}
                {/* Add more messages */}
                {!chatIsLoading && chatUpdates?.length > 0 && (
                  <RequestStatus
                    key={"RequestStatus"}
                    chatUpdates={chatUpdates}
                  />
                )}
              </div>
            )}
            <div ref={messagesContainer} className="invisible h-0 w-0"></div>
            <PopupButton className="hidden" id="JHLfuuD9">
              Use Case Form
            </PopupButton>
            {messages.length > 0 && (
              <div
                ref={inputContainertRef}
                className={`${chatOpen ? "md:w-[92%]" : "md:w-[560px]"} fixed bottom-0 w-full bg-white p-5 pt-0  md:p-6 md:pt-0`}
              >
                <InputContainer
                  isMessageAllowed={
                    !!socket &&
                    socket.readyState === socket.OPEN &&
                    !isMessageStreaming
                  }
                  addUserMessage={createAndSendMessage}
                />
              </div>
            )}
            {functionModal.isOpen && objective_update && (
              <CustomModal
                isOpen={functionModal.isOpen}
                onClose={() => {
                  setFunctionModal({
                    isOpen: false,
                    objective: "",
                    requirements: [],
                  });
                  console.log("closing modal");
                }}
              >
                <div className="z-50 flex h-full items-center justify-center">
                  <ObjectiveModal
                    objectiveUpdate={objective_update as ObjectiveGenerated}
                    createAndSendMessage={createAndSendMessage}
                    setFunctionModal={setFunctionModal}
                    setIsFormModalOpen={setIsFormModalOpen}
                  />
                </div>
              </CustomModal>
            )}
            {isFormModalOpen && (
              <Modal
                isOpen={isFormModalOpen}
                header={"Our bots are working to complete your results."}
                body={
                  <ContactForm
                    onLaunch={() => {
                      setIsFormModalOpen(false);
                      setFunctionModal({
                        isOpen: false,
                        objective: "",
                        requirements: [],
                      });
                    }}
                    createAndSendMessage={createAndSendMessage}
                  />
                }
                actions={[]}
                onClose={() => {
                  setIsFormModalOpen(false);
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  },
);
export default ChatContainer;
