import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import CustomDropdown from "../elements/CustomDropDownMenu";
import { IconButton } from "../elements/IconButton";
import { IconItem } from "../../pages/sidebar/ChatNameBox";
import DeleteIcon from "../svgs/Delete";
import EditIcon from "../svgs/Edit";
import ShareIcon from "../svgs/Share";
import { BiggerThreeDotsIcon } from "../svgs/ThreeDots";
import useChatDetails from "src/hooks/useChatDetails";
import { useParams } from "react-router-dom";
import BookIcon from "../svgs/Book";
import { useStateContext } from "src/context/StateContext";
import { saveChatName } from "../utils";
import { useAuth } from "src/context/AuthContext";

export default function TableHeader({
  showFiltersLibraryButton = true,
}: {
  showFiltersLibraryButton?: boolean;
}) {
  const { chatId: selectedChatId } = useParams();
  const { user } = useAuth();
  const { setFiltersOpen, setChatOpen, chatOpen, filtersOpen, setDpeState } =
    useStateContext();
  const { chatDetails } = useChatDetails(selectedChatId || null);

  // Use optional chaining and nullish coalescing for default value
  const chatName = chatDetails?.chat_name ?? "New Request";

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(chatName);

  useEffect(() => {
    setName(chatName);
  }, [chatName]);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleBlur = async () => {
    setIsEditing(false);
    if (name !== chatName) {
      await saveChatName(
        selectedChatId ?? "",
        name,
        user?.getIdToken() ?? Promise.resolve(""),
      );
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (!name.trim()) {
        toast.error("Chat name cannot be empty.");
        return;
      }

      setIsEditing(false);

      if (name !== chatName) {
        await saveChatName(
          selectedChatId ?? "",
          name,
          user?.getIdToken() ?? Promise.resolve(""),
        );
      }
    }
  };

  useEffect(() => {
    if (isEditing) {
      setTimeout(() => {
        (
          document.getElementById("chat-name-input") as HTMLInputElement
        )?.select();
      }, 0);
    }
  }, [isEditing]);

  return (
    <div className="flex flex-grow items-center justify-between gap-2">
      <div className="hidden items-center justify-center gap-2.5 md:flex">
        {isEditing ? (
          <input
            id="chat-name-input"
            type="text"
            value={name}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            autoFocus
            className="max-w-4xl border-b-2 bg-transparent text-[28px] font-[550] leading-[44.8px] text-black focus:outline-none"
          />
        ) : (
          <h2
            title={chatName}
            className="max-w-2xl cursor-text truncate text-[28px] font-[550] leading-[44.8px] text-black"
            onMouseDown={(e) => e.preventDefault()}
            onDoubleClick={handleDoubleClick}
          >
            {name}
          </h2>
        )}
        <div className="flex h-[42px] w-[42px] items-center justify-center">
          <CustomDropdown
            opener={<BiggerThreeDotsIcon className="hidden" />}
            options={chatOptions()}
            overrideClasses="left-8"
          />
        </div>
      </div>
      {showFiltersLibraryButton && (
        <div className="flex flex-row items-center gap-2 md:gap-4 ">
          <IconButton
            title="Coming soon"
            overrideClasses="h-9  gap-1.5 border-purple text-white  md:h-[42px]  "
            onClick={() => {
              if (!chatOpen) {
                setChatOpen(!chatOpen);
              }
              setDpeState({ isOpen: false, type: "null_dpe" });
              setFiltersOpen(!filtersOpen);
            }}
            variant="fill"
            icon={<BookIcon className="h-4 w-4 fill-white md:h-6 md:w-6" />}
            text={<div className="min-w-max text-sm  md:text-lg ">Toolbox</div>}
          />
        </div>
      )}
    </div>
  );
}

function chatOptions() {
  return [
    <IconItem
      onClick={() => {
        console.log("share clicked");
      }}
      text="Share"
      icon={<ShareIcon />}
    />,
    <IconItem
      onClick={() => {
        console.log("share clicked");
      }}
      text="Rename"
      icon={<EditIcon />}
    />,
    <IconItem
      color="text-error"
      onClick={() => {
        console.log("delete clicked");
      }}
      text="Delete"
      icon={<DeleteIcon />}
    />,
  ];
}
