/*npm install axios */

import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import FormAction from "./FormAction";
import Input from "./Input";
import { signupFields } from "./formFields";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { addUserToLoop, createDataForNewUser } from "./api-loop_os"; // Import the API functions
import Spinner from "../elements/Spinner";
import { sendEmailVerification, User } from "firebase/auth";

const fields = signupFields;
let fieldsState: { [key: string]: any } = {};

fields.forEach((field) => (fieldsState[field.name] = ""));

export default function SignUp() {
  const location = useLocation();

  const isLifetimeDeal = (): boolean => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.has("lifetimedeal");
  };

  const actionCodeSettings = {
    url: isLifetimeDeal()
      ? "https://app.kurationai.com/earlybird"
      : "https://app.kurationai.com/onboarding",
    handleCodeInApp: true,
  };

  const sendVerificationEmail = async (user: User) => {
    try {
      await sendEmailVerification(user, actionCodeSettings);
      console.log("Verification email sent.");
    } catch (error) {
      console.error("Error sending verification email:", error);
    }
  };

  const [signUpState, setSignUpState] = useState(fieldsState);
  const [error, setError] = useState("");
  const { signUp } = useAuth();
  const [timer, setTimer] = useState<number | null>(null);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("timer updating...");
    if (!(error.length > 1)) {
      return;
    }
    const timeId = setTimeout(() => {
      setError("");
    }, 5000);
    setTimer(() => timeId as unknown as number);
    return () => {
      timer && clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [error]);



  const handleChange = (e: ChangeEvent) =>
    setSignUpState({
      ...signUpState,
      [(e.target as HTMLInputElement).name]: (e.target as HTMLInputElement)
        .value,
    });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (signUpState["password"] !== signUpState["confirm-password"]) {
      setError("Passwords don't match.");
      return;
    }
    try {
      setLoading(true);
      const userCred = await signUp(
        signUpState["email"],
        signUpState["password"],
      );

      try {
        // Add user to loop
        const userData: { [key: string]: any } = createDataForNewUser(
          signUpState["email"],
        );
        if (signUpState["firstName"]) {
          userData["firstName"] = signUpState["firstName"];
        }
        if (signUpState["lastName"]) {
          userData["lastName"] = signUpState["lastName"];
        }
        await addUserToLoop(userData, userCred.user.getIdToken());
      } catch (err) {
        console.error(err);
        console.log("something went wrong with adding to loops");
      }
      try {
        await sendVerificationEmail(userCred.user);
      } catch (err) {
        console.error(err);
        console.log("something went wrong with sending email verification.");
      }
      navigate("/verify");
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      if (((error as any).message as string).includes("already-in-use")) {
        setError("Email already in use.");
      } else {
        setError("Something went wrong. Try a different mail or contact us.");
      }
    }
  };

  return (
    <div className="flex flex-col items-center">
      <form
        className="mt-0 w-full md:mt-2  md:max-w-sm"
        onSubmit={handleSubmit}
      >
        {loading && (
          <Spinner
            text="Signing up."
            styleClasses={["backdrop-blur-sm"]}
            onWhiteBackground
          />
        )}
        <div className="">
          {fields.map((field) => (
            <Input
              key={field.id}
              handleChange={handleChange}
              value={signUpState[field.name]}
              labelText={field.labelText}
              labelFor={field.labelFor}
              id={field.id}
              name={field.name}
              type={field.type}
              isRequired={field.isRequired}
              placeholder={field.placeholder}
              customClass={""}
            />
          ))}
          {error.length > 0 && <p>{error}</p>}
          <FormAction type="Button" action="submit" text="Continue" />
        </div>
      </form>
    </div>
  );
}
