import CloseIcon from "../svgs/CloseIcon";
import { useNavigate } from "react-router-dom";
import backend_services from "src/services/backend_service";
import Spinner from "../elements/Spinner";
import { useAuth } from "src/context/AuthContext";
import { useState } from "react";
import { TableState } from "src/hooks/useChatTable";
import { toast } from "react-toastify";
import LinkedInPostLikerForm from "./forms/LinkedInPostLikerForm";
import { useStateContext } from "src/context/StateContext";
import InstallChromeExtension from "../elements/InstallChromeExtension";

interface LinkedInPostLikerProps {
  handleClose: () => void;
  initialPostURL?: string;
  initialLinkedinCookies?: string;
  table_state?: TableState;
  chat_id?: string;
}

const LinkedInPostLiker: React.FC<LinkedInPostLikerProps> = ({
  initialPostURL = "",
  initialLinkedinCookies = "",
  handleClose,
  table_state,
  chat_id,
}) => {
  const { setSidebarOpen } = useStateContext();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [postURL, setPostURL] = useState(initialPostURL);
  const [sessionCookies, setSessionCookies] = useState(initialLinkedinCookies);
  const navigator = useNavigate();

  async function handleMessageSend() {
    try {
      setLoading(true);

      if (!user) {
        toast.error("User is not authenticated.");
        return;
      }

      if (!postURL || postURL.trim() === "") {
        toast.error("Please enter a valid LinkedIn post URL.");
        return;
      }

      const linkedInPostUrlPattern =
        /^https?:\/\/(?:www\.)?linkedin\.com\/posts\/.*$/;

      if (!linkedInPostUrlPattern.test(postURL)) {
        toast.error("Please enter a valid LinkedIn post URL.");
        setLoading(false);
        return;
      }

      if (!sessionCookies || sessionCookies.trim() === "") {
        toast.error("Please enter a session cookie.");
        return;
      }

      const requestBody: any = {
        post_url: postURL,
        linkedin_cookies: sessionCookies,
      };

      let response;

      if (table_state === TableState.ERROR) {
        response = await backend_services.fetchProtectedData(
          `/initial_lists/retry/postLikers/${chat_id}`,
          user.getIdToken(),
          undefined,
          "POST",
          requestBody,
        );

        if (response.ok) {
          const result = await response.json();
          toast.success(result.message || "Retry initiated successfully.");
          handleClose();
        } else {
          throw new Error(await response.text());
        }
      } else {
        response = await backend_services.fetchProtectedData(
          `/initial_lists/postLikers`,
          user.getIdToken(),
          undefined,
          "POST",
          requestBody,
        );

        if (response.ok) {
          const result = await response.json();
          if (result?.chat_id) {
            navigator(`/chat/${result.chat_id}`);
            toast.success(result.message || "Request submitted successfully.");
            handleClose();
            setSidebarOpen(false);
          } else {
            toast.error(result.detail || "An error occurred.");
          }
        } else {
          throw new Error(await response.text());
        }
      }
    } catch (error: any) {
      console.error("Error:", error.message || error);
      toast.error(error.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="fixed left-1/2 top-1/2 z-50 h-[650px] w-[700px] -translate-x-1/2 -translate-y-1/2 transform overflow-y-auto rounded-lg bg-white px-6 py-6 shadow-lg">
      {loading && <Spinner text="Searching..." onWhiteBackground />}
      <div className="flex flex-col gap-5">
        <div>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <img
                src="/linkedin_icon.png"
                alt="LinkedIn Post"
                className="h-12 w-12"
              />
              <div>
                <h2 className="text-xl font-bold">LinkedIn Post Liker</h2>
                <p className="text-sm">
                  Download the chrome extension to find your session cookie.
                </p>
              </div>
            </div>
            <button
              className="text-gray-500 hover:text-gray-700"
              onClick={handleClose}
            >
              <CloseIcon />
            </button>
          </div>
          <LinkedInPostLikerForm
            postURL={postURL}
            setPostURL={setPostURL}
            sessionCookies={sessionCookies}
            setSessionCookies={setSessionCookies}
            onSubmit={handleMessageSend}
          />
        </div>
        <hr className="w-full" />
        <InstallChromeExtension />
      </div>
    </div>
  );
};

export default LinkedInPostLiker;
